@font-face {
  font-family: 'Vitruvius-icon-set';
  src:  url('fonts/Vitruvius-icon-set.eot?iz5215');
  src:  url('fonts/Vitruvius-icon-set.eot?iz5215#iefix') format('embedded-opentype'),
    url('fonts/Vitruvius-icon-set.ttf?iz5215') format('truetype'),
    url('fonts/Vitruvius-icon-set.woff?iz5215') format('woff'),
    url('fonts/Vitruvius-icon-set.svg?iz5215#Vitruvius-icon-set') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="v-icon-"], [class*=" v-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Vitruvius-icon-set' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-icon-demographics:before {
  content: "\e94d";
}
.v-icon-family:before {
  content: "\e94e";
}
.v-icon-financials:before {
  content: "\e94f";
}
.v-icon-lifestyle:before {
  content: "\e950";
}
.v-icon-living:before {
  content: "\e951";
}
.v-icon-moreinfo:before {
  content: "\e952";
}
.v-icon-vehicle:before {
  content: "\e953";
}
.v-icon-building-large:before {
  content: "\e954";
}
.v-icon-building:before {
  content: "\e955";
}
.v-icon-bullhorn:before {
  content: "\e956";
}
.v-icon-handshake:before {
  content: "\e957";
}
.v-icon-mls-title:before {
  content: "\e958";
}
.v-icon-page-image:before {
  content: "\e959";
}
.v-icon-paragraph:before {
  content: "\e95a";
}
.v-icon-region:before {
  content: "\e95b";
}
.v-icon-teams:before {
  content: "\e95c";
}
.v-icon-title:before {
  content: "\e95d";
}
.v-icon-us-map:before {
  content: "\e95e";
}
.v-icon-caret-down:before {
  content: "\e93a";
}
.v-icon-caret-left:before {
  content: "\e946";
}
.v-icon-caret-right:before {
  content: "\e947";
}
.v-icon-caret-up:before {
  content: "\e948";
}
.v-icon-sort-table-header:before {
  content: "\e949";
}
.v-icon-sort:before {
  content: "\e905";
}
.v-icon-down-arrow:before {
  content: "\e90d";
}
.v-icon-down-chevron:before {
  content: "\e90e";
}
.v-icon-left-arrow:before {
  content: "\e917";
}
.v-icon-left-chevron:before {
  content: "\e918";
}
.v-icon-right-arrow:before {
  content: "\e926";
}
.v-icon-right-chevron:before {
  content: "\e927";
}
.v-icon-up-arrow:before {
  content: "\e92b";
}
.v-icon-up-chevron:before {
  content: "\e92c";
}
.v-icon-play-circle:before {
  content: "\e903";
}
.v-icon-map:before {
  content: "\e963";
}
.v-icon-signature:before {
  content: "\e964";
}
.v-icon-activity:before {
  content: "\e95f";
}
.v-icon-draft:before {
  content: "\e94b";
}
.v-icon-people:before {
  content: "\e94a";
}
.v-icon-draw:before {
  content: "\e90c";
}
.v-icon-radius:before {
  content: "\e922";
}
.v-icon-location-services:before {
  content: "\e924";
}
.v-icon-location-pin:before {
  content: "\e919";
}
.v-icon-location-pin-filled:before {
  content: "\e92e";
}
.v-icon-heart:before {
  content: "\e913";
}
.v-icon-heart-filled:before {
  content: "\e92f";
}
.v-icon-heart-broken:before {
  content: "\e930";
}
.v-icon-check:before {
  content: "\e931";
}
.v-icon-check-circle:before {
  content: "\e907";
}
.v-icon-close:before {
  content: "\e90b";
}
.v-icon-close-circle:before {
  content: "\e90a";
}
.v-icon-plus:before {
  content: "\e923";
}
.v-icon-add-circle:before {
  content: "\e900";
}
.v-icon-bell:before {
  content: "\e902";
}
.v-icon-calendar:before {
  content: "\e904";
}
.v-icon-chat:before {
  content: "\e906";
}
.v-icon-clock-reverse:before {
  content: "\e908";
}
.v-icon-clock:before {
  content: "\e909";
}
.v-icon-envelope:before {
  content: "\e90f";
}
.v-icon-expand:before {
  content: "\e910";
}
.v-icon-external-link:before {
  content: "\e911";
}
.v-icon-gear:before {
  content: "\e912";
}
.v-icon-house:before {
  content: "\e914";
}
.v-icon-info-circle:before {
  content: "\e915";
}
.v-icon-layers:before {
  content: "\e916";
}
.v-icon-locked:before {
  content: "\e91a";
}
.v-icon-unlocked:before {
  content: "\e92a";
}
.v-icon-magnifying-glass:before {
  content: "\e91b";
}
.v-icon-money-circle:before {
  content: "\e91c";
}
.v-icon-more--vertical:before {
  content: "\e91d";
}
.v-icon-no-show:before {
  content: "\e91e";
}
.v-icon-page:before {
  content: "\e91f";
}
.v-icon-pencil:before {
  content: "\e920";
}
.v-icon-person:before {
  content: "\e921";
}
.v-icon-question-circle:before {
  content: "\e925";
}
.v-icon-star:before {
  content: "\e928";
}
.v-icon-trash:before {
  content: "\e929";
}
.v-icon-video:before {
  content: "\e92d";
}
.v-icon-attention-circle:before {
  content: "\e901";
}
.v-icon-blogger:before {
  content: "\e960";
}
.v-icon-instagram:before {
  content: "\e961";
}
.v-icon-youtube:before {
  content: "\e962";
}
.v-icon-google1:before {
  content: "\e94c";
}
.v-icon-facebook:before {
  content: "\e932";
}
.v-icon-google:before {
  content: "\e933";
}
.v-icon-pinterest:before {
  content: "\e934";
}
.v-icon-twitter:before {
  content: "\e935";
}
.v-icon-web-page:before {
  content: "\e965";
}
.v-icon-credit-card:before {
  content: "\e936";
}
.v-icon-camera:before {
  content: "\e937";
}
.v-icon-phone:before {
  content: "\e938";
}
.v-icon-printer:before {
  content: "\e939";
}
.v-icon-school-outline:before {
  content: "\e93b";
}
.v-icon-car:before {
  content: "\e93c";
}
.v-icon-school:before {
  content: "\e93d";
}
.v-icon-bath:before {
  content: "\e93e";
}
.v-icon-bed:before {
  content: "\e93f";
}
.v-icon-multi-family:before {
  content: "\e940";
}
.v-icon-condo:before {
  content: "\e941";
}
.v-icon-farm:before {
  content: "\e942";
}
.v-icon-house-property:before {
  content: "\e943";
}
.v-icon-land:before {
  content: "\e944";
}
.v-icon-townhouse:before {
  content: "\e945";
}
